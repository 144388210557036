import { combineReducers } from '@reduxjs/toolkit';

import { ISignUpFormState, SignUpFormReducers } from 'shared/components/SignUpOrSignIn/SignUpForm/reducers';
import { IThanksForRegisteringState, ThanksForRegisteringReducers } from 'shared/components/SignUpOrSignIn/ThanksForRegistering/reducers';

export interface IInviteState { // Should mirror InviteReducers
    signUpFormState : ISignUpFormState;
    thanksForRegisteringState : IThanksForRegisteringState;
}

export const InviteReducers = combineReducers({
    signUpFormState: SignUpFormReducers,
    thanksForRegisteringState: ThanksForRegisteringReducers,
});
