import { createRoot } from "react-dom/client";
import { combineReducers } from '@reduxjs/toolkit';

import { Auth } from 'aws-amplify';
import { Provider } from 'shared/components/Provider';

import { SignUpOptionsReducers } from 'shared/components/SignUpOrSignIn/SignUpOptions/reducers';
import { InviteReducers } from './inviteReducers';
import { UserSessionReducers } from './reducers';

import { ConnectedInviteApp } from './InviteApp';
import { ConnectedUserButtonApp } from './UserButtonApp';
import { ConnectedUserLoginApp } from './UserLoginApp';
import { ConnectedUserLogoutApp } from './UserLogoutApp';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';

import {
    cookieManager,
    queryStringManager,
    unauthenticatedUserSessionManager,
    userAccountInfoService,
    userAccountManager,
    userInviteService,
    userPasswordManager,
    userSessionManager,
} from 'shared/lib/manager';
import { SignUpOptionsServices } from "./types";

const signUpOptionsReducers = combineReducers({
    userSession: UserSessionReducers,
    signUpOptionsState: SignUpOptionsReducers,
});

const inviteReducers = combineReducers({
    userSession: UserSessionReducers,
    inviteState: InviteReducers,
    signUpOptionsState: SignUpOptionsReducers, // used by ConnectedSignupOptions in InviteApp–
});

// TODO 02/10/2025: is this used at all?? can we get rid of this set of code??
// TODO what about the amplify config we configure on all of the index pages

// MAIN
const services : SignUpOptionsServices = {
    userSessionCreator: userSessionManager,
    userSessionReader: userSessionManager,
    userSessionTerminator: userSessionManager,
    userAccountInfoReader: userAccountInfoService,
    queryStringReader: queryStringManager,
    cookieDeleter: cookieManager,
    cookieReader: cookieManager,
    userPasswordManager,
    userSessionManager,
    userAccountManager,
    unauthenticatedUserSessionManager,
    djangoApiManager: new DjangoApiManager(),
    userInviteService
};
Auth.configure(window.AMPLIFY_CONFIG);

const userLoginAppElement = document.getElementById('user-login-app');
if (userLoginAppElement) {
    const userLoginRoot = createRoot(userLoginAppElement);
    userLoginRoot.render(
        <Provider appReducers={ signUpOptionsReducers } services={ services }>
            <ConnectedUserLoginApp />
        </Provider>
    );
}

const userButtonAppElement = document.getElementById('user-button-app-mount');
if (userButtonAppElement) {
    const userButtonRoot = createRoot(userButtonAppElement);
    userButtonRoot.render(
        <Provider appReducers={ signUpOptionsReducers } services={ services }>
            <ConnectedUserButtonApp />
        </Provider>
    );
}

const userLogoutAppElement = document.getElementById('user-logout-app');
if (userLogoutAppElement) {
    const userLogoutRoot = createRoot(userLogoutAppElement);
    userLogoutRoot.render(
        <Provider appReducers={ signUpOptionsReducers } services={ services }>
            <ConnectedUserLogoutApp />
        </Provider>
    );
}

const inviteAppElement = document.getElementById('invite-app');
if (inviteAppElement) {
    const inviteAppRoot = createRoot(inviteAppElement);
    inviteAppRoot.render(
        <Provider appReducers={ inviteReducers } services={ services }>
            <ConnectedInviteApp />
        </Provider>
    );
}
