import React from 'react';
import { connect } from 'react-redux';

import { InvalidInviteException } from 'api/UserAccount/exceptions/InvalidInviteException';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { Button } from 'shared/components/Button';
import { LoadingCover } from 'shared/components/LoadingCover';
import { ISignUpData } from 'shared/components/SignUpOrSignIn/ISignUpData';
import { SignUpFormDispatch, SignUpFormActions } from 'shared/components/SignUpOrSignIn/SignUpForm/actions';
import { ISignUpFormState } from 'shared/components/SignUpOrSignIn/SignUpForm/reducers';
import { SignUpOptionsFormActions } from 'shared/components/SignUpOrSignIn/SignUpOptions/actions';
import { ConnectedSignUpOptions } from 'shared/components/SignUpOrSignIn/SignUpOptions/SignUpOptions';
import { ThanksForRegisteringDispatch, ThanksForRegisteringActions } from 'shared/components/SignUpOrSignIn/ThanksForRegistering/actions';
import { IThanksForRegisteringState } from 'shared/components/SignUpOrSignIn/ThanksForRegistering/reducers';
import { ThanksForRegistering } from 'shared/components/SignUpOrSignIn/ThanksForRegistering/ThanksForRegistering';
import { URLStringUtils } from 'shared/utils/urlStringUtils';

import { UserSessionActions } from './actions';
import { UserSessionInviteActions } from './inviteActions';
import { IInviteState } from './inviteReducers';
import { SignUpOptionsDispatch } from './types';

import 'shared/css/SignUpOptionsForm.scss';
import './userSession.scss';

interface IInviteAppProps {
    signUpFormState : ISignUpFormState;
    thanksForRegisteringState : IThanksForRegisteringState;
}

interface IBoundInviteAppProps extends IInviteAppProps {
    dispatch : SignUpOptionsDispatch & ThanksForRegisteringDispatch & SignUpFormDispatch;
}

interface IInviteAppState {
    hasUserJoinedEstablishment : boolean;
    inviteBannerText : string | null;
    inviteLinkValue : string;
    inviteTargetId : string | null;
    inviteTargetName : string;
    isAdmin : boolean;
    isInviteActive : boolean;
    isLoading : boolean;
    isLoggedIn : boolean;
}

// todo: rename this to AcceptInviteApp?
export class InviteApp extends React.Component<IBoundInviteAppProps, IInviteAppState> {
    private readonly currentUser : string;
    constructor(props : IBoundInviteAppProps) {
        super(props);
        this.state = {
            hasUserJoinedEstablishment: false,
            inviteBannerText: null,
            inviteLinkValue: URLStringUtils.getQueryParameterFromUrl('invite_link'),
            inviteTargetId: null,
            inviteTargetName: '',
            isAdmin: window.GLOBAL_USER_IS_ADMIN,
            isInviteActive: false,
            isLoading: false,
            isLoggedIn: window.GLOBAL_USER_ID !== '',
        };
        this.currentUser = window.GLOBAL_USER_NAME;
    }

    public UNSAFE_componentWillMount() {
        // if we have an invitation, fetch relevant information
        this.props.dispatch(SignUpOptionsFormActions.onSetActiveForm('signUpForm'));
        this.fetchUserInviteInfo();
    }

    public render() {
        const {
            signUpFormState,
            thanksForRegisteringState,
        } = this.props;

        const {
            hasUserJoinedEstablishment,
            inviteBannerText,
            inviteLinkValue,
            isAdmin,
            isInviteActive,
            isLoading,
            isLoggedIn,
        } = this.state;

        let headerTitle : string = '';
        if (inviteLinkValue) {
            if (hasUserJoinedEstablishment && isLoggedIn) {
                headerTitle = 'You are already a member of this establishment';
            }
        }

        const getFormdDisplayContents = () : JSX.Element | null => {
            // 1. bevspot admin creating a new user
            if (isAdmin && !isInviteActive) {
                // NOTE: `UNSAFE_componentWillMount` sets `signUpForm` as the active
                // form already, but if that ever changes, we need to ensure
                // for this case it is made active
                return (
                    <React.Fragment>
                        <div className="form-section">
                            <h4>BevSpot Admin User Creation</h4>
                            <h5>{ inviteBannerText }</h5>
                            <p>You are logged in as { window.GLOBAL_USER_NAME }</p>
                        </div>
                        <ConnectedSignUpOptions
                            availableFormOptions={ ['signUpForm'] }
                            isEmbeddedInModal={ false }
                            isDismissible={ true }
                            formIsVisibleOnLoad={ true }
                            additionalUserFieldsAreShown={ true }
                            additionalRetailerFieldsAreShown={ false }
                            additionalRetailerFieldsAreRequired={ false }
                            onSuccessfulSignUp={ this.onSuccessfulAdminSignUp }
                            onSuccessfulSignIn={ this.onSuccessfulSignIn }
                        />
                    </React.Fragment>
                );

            // 2. a non-bevspot admin following an invalidated invite link, or the `invite_link` is missing
            } else if (!isAdmin && !isInviteActive) {
                return (
                    <div className="form-section">
                        <div className="invite-banner-wrapper">
                            <h4 className="invite-error">This invitation is no longer valid</h4>
                        </div>
                    </div>
                );

            // 3. a logged in, non-bevspot admin user accepting an invite to a retailer they don't yet belong to
            } else if (isLoggedIn && !hasUserJoinedEstablishment) {
                return (
                    <div className="form-section">
                        <h5>{ inviteBannerText }</h5>
                        <p>You are logged in as { window.GLOBAL_USER_NAME }</p>
                        <Button
                            buttonClassName="primary"
                            isDisabled={ false }
                            isLoading={ this.state.isLoading }
                            onClick={ this.onLoggedInUserAcceptInvite }
                        >
                            Accept Invitation
                        </Button>
                        <Button
                            buttonClassName="primary flat normal large"
                            isDisabled={ false }
                            isLoading={ this.state.isLoading }
                            onClick={ this.notUserLogout }
                        >
                            Not { this.currentUser }?
                        </Button>
                    </div>
                );

            // 4. a logged in, non-bevspot admin user accepting an invite to a retailer they already belong to
            } else if (isLoggedIn && hasUserJoinedEstablishment) {
                return(
                    <div className="form-section">
                        <h5>{ headerTitle }</h5>
                        <p>You are logged in as { this.currentUser }</p>
                        <Button
                            buttonClassName="primary"
                            isDisabled={ false }
                            isLoading={ isLoading }
                            onClick={ this.redirectToUrl }
                        >
                            Continue
                        </Button>
                        <Button
                            buttonClassName="primary flat normal large"
                            isDisabled={ false }
                            isLoading={ this.state.isLoading }
                            onClick={ this.notUserLogout }
                        >
                            Not { this.currentUser }?
                        </Button>
                    </div>
                );
            }

            // 5. a not logged in user accepting an invite who must first either sign up or sign in
            if (!signUpFormState.hasSuccessfullySubmitted) {
                return (
                    <ConnectedSignUpOptions
                        availableFormOptions={ ['signUpForm', 'signInForm'] }
                        isEmbeddedInModal={ false }
                        isDismissible={ true }
                        formIsVisibleOnLoad={ true }
                        additionalUserFieldsAreShown={ true }
                        additionalRetailerFieldsAreShown={ false }
                        additionalRetailerFieldsAreRequired={ false }
                        onSuccessfulSignUp={ this.onSuccessfulSignUp }
                        onSuccessfulSignIn={ this.onSuccessfulSignIn }
                    />
                );

            // 5a. a not logged in user who just signed up for a new account
            } else {
                return (
                    <ThanksForRegistering
                        isEmbeddedInModal={ false }
                        onSendEmailAgainClick={ this.onSendEmailAgainClick }
                        onCloseButtonClick={ this.onCloseButtonClick }
                        isResendingEmail={ thanksForRegisteringState.isResendingEmail }
                        isEmailResent={ thanksForRegisteringState.isEmailResent }
                    />
                );
            }
        };

        return (
            <div>
                <header className="logo-header">
                    <a href="https://www.bevspot.com" className="brand-logo dark transition">
                        <span className="bevicon bevico-bevspot-logo transition" />
                        <span className="bevicon bevico-logo-text transition" />
                    </a>
                </header>
                { isLoading &&
                    <LoadingCover
                        className="overlay-light"
                        hasLoadingIcon={ true }
                    />
                }
                { !isLoading &&
                    <div className="login-container">
                        <div className="sign-up-or-sign-in-form">
                            { getFormdDisplayContents() }
                        </div>
                    </div>
                }
            </div>
        );
    }

    private readonly fetchUserInviteInfo = () => {
        // if we have an invitation, fetch relevant information
        if (this.state.inviteLinkValue) {
            this.setState({
                isLoading: true
            });
            return this.props.dispatch(UserSessionInviteActions.getInvite(this.state.inviteLinkValue))
                .then((inviteInfo) => {
                    this.setState({
                        inviteBannerText: inviteInfo.getInvitedByUserName() + ' has invited you to join ' + inviteInfo.getInvitedToName(),
                        isInviteActive: true,
                        // NOTE: this is null when the invite is to a group
                        inviteTargetId: inviteInfo.getInvitationType() === 'retailer' ? inviteInfo.getInvitedToId().getValue() : null,
                        inviteTargetName: inviteInfo.getInvitationType() === 'retailer' ? inviteInfo.getInvitedToName() : '',
                        hasUserJoinedEstablishment: inviteInfo.hasUserJoinedEstablishment(),
                    });
                })
                .catch((error) => {
                    if (error instanceof InvalidInviteException) {
                        this.setState({
                            isInviteActive: false
                        });
                    } else {
                        throw new Error(`unexpected ${error}`);
                    }
                })
                .then(() => {
                    this.setState({
                        isLoading: false
                    });
                });
        }
        return Promise.resolve();
    }

    private readonly onSuccessfulSignUp = (userAccountIdentifier : UserAccountId, signUpData : ISignUpData) => {
        return this.props.dispatch(UserSessionActions.onSuccessfulSignUp(userAccountIdentifier, signUpData, this.state.inviteTargetId, this.state.isAdmin))
        .then(() => {
            this.fetchUserInviteInfo()
            .then(() => {
                this.onAcceptInvitation(userAccountIdentifier, false);
            });
        });
    }

    private readonly onSuccessfulAdminSignUp = (userAccountIdentifier : UserAccountId, signUpData : ISignUpData) => {
        return this.props.dispatch(UserSessionActions.onSuccessfulSignUp(userAccountIdentifier, signUpData, this.state.inviteTargetId, this.state.isAdmin))
        .then((userAccountId) => {
            window.location.href = `/admin_tools/manage/user/${ userAccountId.getValue() }`;
        });
    }

    private readonly onSuccessfulSignIn = () => {
        return this.props.dispatch(UserSessionInviteActions.onSuccessfulSignIn())
        .then((userAccountIdentifier) => {
            if (userAccountIdentifier) {
                this.fetchUserInviteInfo()
                .then(() => {
                    if (this.state.hasUserJoinedEstablishment) {
                        this.redirectToUrl();
                    } else {
                        this.onAcceptInvitation(userAccountIdentifier, true);
                    }
                });
            }
        });
    }

    private readonly onSendEmailAgainClick = () => {
        this.props.dispatch(ThanksForRegisteringActions.onResendEmail(this.props.signUpFormState.validationInputDataByFieldName.emailAddress.value));
    }

    private readonly onCloseButtonClick = () => {
        this.props.dispatch(SignUpFormActions.onResetForm());
    }

    private readonly onLoggedInUserAcceptInvite = () => {
        return this.onAcceptInvitation(new UserAccountId(window.GLOBAL_USER_ID), true);
    }

    private readonly onAcceptInvitation = (userID : UserAccountId, redirect : boolean) => {
        return this.props.dispatch(UserSessionInviteActions.acceptInvite(userID, this.state.inviteLinkValue))
        .then((url) => {
            if (redirect) {
                window.location.href = url;
            }
        });
    }

    private readonly notUserLogout = () => {
        return this.props.dispatch(UserSessionInviteActions.logoutUser())
        .then(() => {
            this.setState({
                isLoggedIn: false,
                hasUserJoinedEstablishment: false
            });
            this.props.dispatch(SignUpOptionsFormActions.onSetActiveForm('signInForm'));
        });
    }

    private readonly redirectToUrl = () => {
        return this.props.dispatch(UserSessionInviteActions.invalidateInvite(this.state.inviteLinkValue))
        .then((url) => {
            window.location.href = url;
        });
    }
}

interface IState {
    inviteState : IInviteState;
}

const mapStateToProps = (state : IState) : IInviteAppProps => {
    return {
        signUpFormState: state.inviteState.signUpFormState,
        thanksForRegisteringState: state.inviteState.thanksForRegisteringState,
    };
};

export const ConnectedInviteApp = connect<IInviteAppProps, object, object, IState>(mapStateToProps)(InviteApp);
