import React from 'react';
import { connect } from 'react-redux';

import { LogoutView } from 'shared/components/LogoutView';
import { UserSessionActions } from './actions';
import { SignUpOptionsDispatch } from './types';

interface IUserLogoutAppProps {
    dispatch : SignUpOptionsDispatch;
}

export class UserLogoutApp extends React.Component<IUserLogoutAppProps, object> {

    private handleSubmit : () => void;

    constructor(props : IUserLogoutAppProps) {
        super(props);

        this.handleSubmit = () => {
            props.dispatch(UserSessionActions.logoutUser());
        };
    }

    public render() {
        return (
            <div>
                <LogoutView
                    submit={ this.handleSubmit }
                />
            </div>
        );
    }
}

const mapStateToProps = (state : any) => ({
});

export const ConnectedUserLogoutApp = connect<object, object, object>(mapStateToProps)(UserLogoutApp);
